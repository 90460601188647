import * as i0 from '@angular/core';
import { InjectionToken, Injectable, NgModule, EventEmitter, Component, Input, Output, Optional, Inject, ViewChild, ViewChildren } from '@angular/core';
import * as i2$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { shareReplay, map, of, NEVER, Subscription, EMPTY, filter } from 'rxjs';
import * as i2 from '@angular/common/http';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import * as i3 from '@soleran/ngx-common';
import { QueryPickerModule, PopoverModule } from '@soleran/ngx-common';
import * as i1$2 from '@soleran/ngx-module';
import { ModuleModule } from '@soleran/ngx-module';
import * as i3$2 from '@angular/forms';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import * as i1$1 from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import * as i4 from '@angular/material/form-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as i5 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import * as i10 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import * as i1 from '@soleran/ngx-role';
import { RoleModule } from '@soleran/ngx-role';
import * as i6 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i7 from '@angular/material/chips';
import { MatChipsModule } from '@angular/material/chips';
import { PageType } from '@soleran/contracts';
import * as i3$1 from '@soleran/ngx-page';
import { PageModule } from '@soleran/ngx-page';
import * as i11 from '@angular/material/select';
import { MatSelectModule } from '@angular/material/select';
import * as i12 from '@angular/material/core';
import * as i4$1 from '@angular/material/list';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProfileModule } from '@soleran/ngx-profile';
import * as i5$1 from '@angular/cdk/drag-drop';
import { moveItemInArray, DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as i3$3 from '@angular/material/expansion';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
function AppFormRolePickerComponent_mat_chip_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-chip", 5);
    i0.ɵɵlistener("removed", function AppFormRolePickerComponent_mat_chip_4_Template_mat_chip_removed_0_listener() {
      const ctx_r1 = i0.ɵɵrestoreView(_r1);
      const role_r3 = ctx_r1.$implicit;
      const selected_r4 = ctx_r1.ngForOf;
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.remove(selected_r4, role_r3));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementStart(2, "button", 6)(3, "mat-icon");
    i0.ɵɵtext(4, "cancel");
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const role_r3 = ctx.$implicit;
    i0.ɵɵproperty("removable", true);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", role_r3.name, " ");
  }
}
const _c0 = a0 => [a0];
function AppFormNavPickerComponent_mat_option_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 6)(1, "mat-icon");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const option_r1 = ctx.$implicit;
    i0.ɵɵproperty("value", option_r1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(option_r1.icon);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(option_r1.type);
  }
}
function AppFormNavPickerComponent_ng_container_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 7)(2, "label");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "button", 8);
    i0.ɵɵlistener("pick", function AppFormNavPickerComponent_ng_container_8_Template_button_pick_4_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onDashboardPick($event));
    });
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r2.getSelectedLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("solQueryPicker", ctx_r2.dashboards)("selectedItems", i0.ɵɵpureFunction1(4, _c0, ctx_r2.selectedDashboard));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (ctx_r2.selectedDashboard == null ? null : ctx_r2.selectedDashboard.name) || "Select Dashboard", " ");
  }
}
function AppFormNavPickerComponent_ng_container_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 7)(2, "label");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "button", 8);
    i0.ɵɵlistener("pick", function AppFormNavPickerComponent_ng_container_9_Template_button_pick_4_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onReportPick($event));
    });
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r2.getSelectedLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("solQueryPicker", ctx_r2.reports)("selectedItems", i0.ɵɵpureFunction1(4, _c0, ctx_r2.selectedReport));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (ctx_r2.selectedReport == null ? null : ctx_r2.selectedReport.name) || "Select Report", " ");
  }
}
function AppFormNavPickerComponent_ng_container_10_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "mat-form-field")(2, "mat-label");
    i0.ɵɵtext(3, "Link");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "input", 9);
    i0.ɵɵlistener("ngModelChange", function AppFormNavPickerComponent_ng_container_10_Template_input_ngModelChange_4_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onLinkChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngModel", ctx_r2.navConfig.link);
  }
}
const _c1 = () => [];
const _c2 = () => ({});
function AppFormComponent_h2_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.title);
  }
}
function AppFormComponent_div_13_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8)(1, "button", 9);
    i0.ɵɵlistener("click", function AppFormComponent_div_13_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.onCancel());
    });
    i0.ɵɵtext(2, "Cancel");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 10);
    i0.ɵɵtext(4, " Save ");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    i0.ɵɵadvance(3);
    i0.ɵɵstyleProp("margin-left", 8, "px");
  }
}
function AppFormModulePickerComponent_mat_chip_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-chip", 5);
    i0.ɵɵlistener("removed", function AppFormModulePickerComponent_mat_chip_4_Template_mat_chip_removed_0_listener() {
      const ctx_r1 = i0.ɵɵrestoreView(_r1);
      const module_r3 = ctx_r1.$implicit;
      const selected_r4 = ctx_r1.ngForOf;
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.remove(selected_r4, module_r3));
    });
    i0.ɵɵelementStart(1, "mat-icon", 6);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3);
    i0.ɵɵelementStart(4, "button", 7)(5, "mat-icon");
    i0.ɵɵtext(6, "cancel");
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const module_r3 = ctx.$implicit;
    i0.ɵɵproperty("removable", true);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(module_r3.icon);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", module_r3.name, " ");
  }
}
const _c3 = ["*"];
function AppListComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1, " No apps here ... ");
    i0.ɵɵelementContainerEnd();
  }
}
function AppListComponent_ng_template_2_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵlistener("click", function AppListComponent_ng_template_2_div_0_Template_div_click_0_listener() {
      const app_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      const ctx_r3 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r3.select.emit(app_r3));
    });
    i0.ɵɵelementStart(1, "sol-app-list-item", 8, 1);
    i0.ɵɵlistener("save", function AppListComponent_ng_template_2_div_0_Template_sol_app_list_item_save_1_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r3.save.emit($event));
    })("delete", function AppListComponent_ng_template_2_div_0_Template_sol_app_list_item_delete_1_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r3.onDelete($event));
    })("change", function AppListComponent_ng_template_2_div_0_Template_sol_app_list_item_change_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r3.handleAppListItem());
    })("updateApp", function AppListComponent_ng_template_2_div_0_Template_sol_app_list_item_updateApp_1_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r3.handleUpdateApp($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const app_r3 = ctx.$implicit;
    const item_r5 = i0.ɵɵreference(2);
    i0.ɵɵproperty("cdkDragDisabled", item_r5.expanded);
    i0.ɵɵadvance();
    i0.ɵɵproperty("app", app_r3);
  }
}
function AppListComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, AppListComponent_ng_template_2_div_0_Template, 3, 2, "div", 6);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngForOf", ctx_r3.apps);
  }
}
const desc = '[@soleran/ngx-app] AppModuleConfig';
const AppModuleConfig = new InjectionToken(desc);
class AppService {
  constructor(_config, _http) {
    this._config = _config;
    this._http = _http;
    this.apiBaseUrl = this._config.apiBaseUrl;
    this.apiPath = `${this.apiBaseUrl}/api/app`;
  }
  create(model) {
    const url = this.apiPath;
    if (!model.navConfig.type) {
      model.navConfig.type = 'Link';
    }
    return this._http.post(url, model);
  }
  get() {
    if (!arguments.length) return this._get();
    return this._getOne(arguments[0]);
  }
  update(id, model) {
    const url = `${this.apiPath}/${id}`;
    if (!model.navConfig.type) {
      model.navConfig.type = 'Link';
    }
    const response = this._http.put(url, model);
    return response.pipe(shareReplay(1));
  }
  delete(id) {
    const url = `${this.apiPath}/${id}`;
    const response = this._http.delete(url);
    return response.pipe(shareReplay(1));
  }
  _get() {
    const url = this.apiPath;
    const response = this._http.get(url);
    return response.pipe(shareReplay(1));
  }
  _getOne(id) {
    const url = `${this.apiPath}/${id}`;
    const response = this._http.get(url);
    return response.pipe(shareReplay(1));
  }
  static {
    this.ɵfac = function AppService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppService)(i0.ɵɵinject(AppModuleConfig), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AppService,
      factory: AppService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppService, [{
    type: Injectable
  }], () => [{
    type: AppModuleConfig
  }, {
    type: i2.HttpClient
  }], null);
})();
class AppModule {
  static forRoot(config) {
    config = config || {
      apiBaseUrl: 'https://app.soleran.dev'
    };
    return {
      ngModule: AppModule,
      providers: [{
        provide: AppModuleConfig,
        useValue: config
      }, AppService]
    };
  }
  static {
    this.ɵfac = function AppModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule]
    }]
  }], null, null);
})();
class AppFormRolePickerComponent {
  set selectedRoleIds(value) {
    this._selectedRoleIds = value || [];
    this.selected = this.roles.pipe(map(all => {
      const ids = new Set(this._selectedRoleIds);
      return all.filter(m => ids.has(m.id));
    }));
  }
  constructor(_roleService) {
    this._roleService = _roleService;
    this.change = new EventEmitter();
    this.selected = of([]);
    this.separatorKeysCodes = [ENTER, COMMA];
    this.roles = this._roleService.getDataRole().pipe(map(v => v.orderBy(m => m.name)), shareReplay(1));
    this._selectedRoleIds = [];
  }
  onPick(selected) {
    this.selectedRoleIds = selected.map(m => m.id);
    this.change.emit(selected);
  }
  remove(selected, role) {
    selected = selected.filter(m => m !== role);
    this.selectedRoleIds = selected.map(m => m.id);
    this.change.emit(selected);
  }
  static {
    this.ɵfac = function AppFormRolePickerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppFormRolePickerComponent)(i0.ɵɵdirectiveInject(i1.RoleService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppFormRolePickerComponent,
      selectors: [["sol-app-form-role-picker"]],
      inputs: {
        selectedRoleIds: "selectedRoleIds"
      },
      outputs: {
        change: "change"
      },
      decls: 10,
      vars: 15,
      consts: [[1, "app-form-role-picker-form-field", 3, "pick", "floatLabel", "solQueryPicker", "displayProperty", "selectedItems", "multiple"], ["aria-label", "Role selection"], [3, "removable", "removed", 4, "ngFor", "ngForOf"], ["matInput", "", "type", "text", "name", "rolePickerInput", "autocomplete", "nope"], ["align", "end"], [3, "removed", "removable"], ["matChipRemove", "", "aria-label", "'remove ' + keyword"]],
      template: function AppFormRolePickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "mat-form-field", 0);
          i0.ɵɵlistener("pick", function AppFormRolePickerComponent_Template_mat_form_field_pick_0_listener($event) {
            return ctx.onPick($event);
          });
          i0.ɵɵelementStart(1, "mat-label");
          i0.ɵɵtext(2, "Role(s)");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "mat-chip-listbox", 1);
          i0.ɵɵtemplate(4, AppFormRolePickerComponent_mat_chip_4_Template, 5, 2, "mat-chip", 2);
          i0.ɵɵpipe(5, "async");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(6, "input", 3);
          i0.ɵɵelementStart(7, "mat-hint", 4);
          i0.ɵɵtext(8);
          i0.ɵɵpipe(9, "async");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_8_0;
          i0.ɵɵproperty("floatLabel", "always")("solQueryPicker", ctx.roles)("displayProperty", "name")("selectedItems", ctx.selected)("multiple", true);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(5, 11, ctx.selected));
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("opacity", 0)("cursor", "pointer");
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" Selected: ", (tmp_8_0 = i0.ɵɵpipeBind1(9, 13, ctx.selected)) == null ? null : tmp_8_0.length, "");
        }
      },
      dependencies: [i2$1.NgForOf, i3.QueryPickerDirective, i4.MatFormField, i4.MatLabel, i4.MatHint, i5.MatInput, i6.MatIcon, i7.MatChip, i7.MatChipListbox, i7.MatChipRemove, i2$1.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:flex;flex-direction:column}.app-form-role-picker-form-field[_ngcontent-%COMP%]{cursor:pointer}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppFormRolePickerComponent, [{
    type: Component,
    args: [{
      selector: 'sol-app-form-role-picker',
      template: `
    <mat-form-field
      class="app-form-role-picker-form-field"
      [floatLabel]="'always'"
      [solQueryPicker]="roles"
      [displayProperty]="'name'"
      [selectedItems]="selected"
      [multiple]="true"
      (pick)="onPick($event)"
    >
      <mat-label>Role(s)</mat-label>

      <mat-chip-listbox aria-label="Role selection">
        <mat-chip
          *ngFor="let role of selected | async as selected"
          [removable]="true"
          (removed)="remove(selected, role)"
        >
          {{ role.name }}
          <button matChipRemove aria-label="'remove ' + keyword">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-listbox>
      <input
        matInput
        type="text"
        name="rolePickerInput"
        autocomplete="nope"
        [style.opacity]="0"
        [style.cursor]="'pointer'"
      />

      <mat-hint align="end">
        Selected: {{ (selected | async)?.length }}</mat-hint
      >
    </mat-form-field>
  `,
      styles: [":host{display:flex;flex-direction:column}.app-form-role-picker-form-field{cursor:pointer}\n"]
    }]
  }], () => [{
    type: i1.RoleService
  }], {
    selectedRoleIds: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
const APP_FORM_NAV_PICKER_OPTIONS = [{
  type: 'Link',
  icon: 'link'
}, {
  type: 'Dashboard',
  icon: 'dashboard'
}, {
  type: 'Report',
  icon: 'table_chart'
}];
const AppFormModuleConfig = new InjectionToken('AppFormModuleConfig');
const AppFormModuleConfigProvider = {
  provide: AppFormModuleConfig,
  useValue: {
    reportApiBaseUrl: 'https://report.soleran.dev'
  }
};
class AppFormNavPickerComponent {
  get selectedOption() {
    const option = APP_FORM_NAV_PICKER_OPTIONS.find(opt => opt.type === this.navConfig.type);
    return option || APP_FORM_NAV_PICKER_OPTIONS[0];
  }
  set selectedOption(option) {
    this.navConfig.type = option?.type || 'Link';
  }
  constructor(_http, _config, _pageService) {
    this._http = _http;
    this._config = _config;
    this._pageService = _pageService;
    this.navConfig = {};
    this.change = new EventEmitter();
    this.options = APP_FORM_NAV_PICKER_OPTIONS;
    this.reports = [];
    this.dashboards = [];
    this.dashboards$ = NEVER;
    this._reportApiBaseUrl = this._config.reportApiBaseUrl;
    this._subs = new Subscription();
    this.dashboards$ = this._pageService.get().pipe(map(pages => pages.filter(page => page.type === PageType.Dashboard)));
    this.dashboards$.subscribe({
      next: dashboards => {
        this.dashboards = dashboards;
        // console.log({dashboards});
      }
    });
  }
  get selectedReport() {
    return this.reports.find(r => r.id === this.navConfig.reportId);
  }
  get selectedDashboard() {
    return this.dashboards.find(r => r.id === this.navConfig.dashboardId);
  }
  ngOnInit() {
    this._subs.add(this._getReports().subscribe(reports => {
      this.reports = reports.filter(v => !v.isTemplate && v.scopeConfigs);
      // console.log(this.reports);
    }));
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }
  onDashboardPick(dashboard) {
    this.navConfig.dashboardId = dashboard.id;
    this.change.emit(this.navConfig);
  }
  onReportPick(report) {
    this.navConfig.reportId = report.id;
    this.change.emit(this.navConfig);
  }
  onLinkChange(link) {
    this.navConfig.dashboardId = '';
    this.navConfig.reportId = '';
    this.navConfig.link = link;
    this.change.emit(this.navConfig);
  }
  // private _generateHardcodedOption(entity: string, length: number) {
  //   return new Array(length).fill(null).map((_, i) => ({
  //     id: `${i + 1}`,
  //     name: `Hardcoded ${entity} ${i + 1}`,
  //   }));
  // }
  _getReports() {
    const url = `${this._reportApiBaseUrl}/api/report-config`;
    const response = this._http.get(url);
    response.subscribe({
      next: response => {
        // console.log({response});
      }
    });
    const result = response.pipe(shareReplay(1));
    return result;
  }
  getSelectedLabel() {
    switch (this.selectedOption.type) {
      case 'Dashboard':
        return 'Selected Dashboard:';
      case 'Report':
        return 'Selected Report:';
      default:
        return 'Link';
    }
  }
  static {
    this.ɵfac = function AppFormNavPickerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppFormNavPickerComponent)(i0.ɵɵdirectiveInject(i2.HttpClient), i0.ɵɵdirectiveInject(AppFormModuleConfig), i0.ɵɵdirectiveInject(i3$1.PageService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppFormNavPickerComponent,
      selectors: [["sol-app-form-nav-picker"]],
      inputs: {
        navConfig: "navConfig"
      },
      outputs: {
        change: "change"
      },
      decls: 11,
      vars: 6,
      consts: [["placeholder", "Placeholder", "name", "selectedOption", 3, "ngModelChange", "ngModel"], [3, "value", 4, "ngFor", "ngForOf"], ["matPrefix", ""], [3, "ngSwitch"], [4, "ngSwitchCase"], [4, "ngSwitchDefault"], [3, "value"], [1, "label-button-group"], ["type", "button", "mat-stroked-button", "", "displayProperty", "name", 3, "pick", "solQueryPicker", "selectedItems"], ["type", "text", "matInput", "", "name", "linkInput", 3, "ngModelChange", "ngModel"]],
      template: function AppFormNavPickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "mat-form-field")(1, "mat-label");
          i0.ɵɵtext(2, "Action");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "mat-select", 0);
          i0.ɵɵtwoWayListener("ngModelChange", function AppFormNavPickerComponent_Template_mat_select_ngModelChange_3_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.selectedOption, $event) || (ctx.selectedOption = $event);
            return $event;
          });
          i0.ɵɵtemplate(4, AppFormNavPickerComponent_mat_option_4_Template, 5, 3, "mat-option", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "mat-icon", 2);
          i0.ɵɵtext(6);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(7, "div", 3);
          i0.ɵɵtemplate(8, AppFormNavPickerComponent_ng_container_8_Template, 6, 6, "ng-container", 4)(9, AppFormNavPickerComponent_ng_container_9_Template, 6, 6, "ng-container", 4)(10, AppFormNavPickerComponent_ng_container_10_Template, 5, 1, "ng-container", 5);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵtwoWayProperty("ngModel", ctx.selectedOption);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.options);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.selectedOption.icon);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitch", ctx.selectedOption.type);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "Dashboard");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "Report");
        }
      },
      dependencies: [i2$1.NgForOf, i2$1.NgSwitch, i2$1.NgSwitchCase, i2$1.NgSwitchDefault, i3$2.DefaultValueAccessor, i3$2.NgControlStatus, i3$2.NgModel, i3.QueryPickerDirective, i4.MatFormField, i4.MatLabel, i4.MatPrefix, i5.MatInput, i6.MatIcon, i10.MatButton, i11.MatSelect, i12.MatOption],
      styles: ["[_nghost-%COMP%]{display:flex;flex-direction:column}mat-form-field[_ngcontent-%COMP%]{width:100%}.label-button-group[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:flex-start}.label-button-group[_ngcontent-%COMP%] > label[_ngcontent-%COMP%]{font-size:.8rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppFormNavPickerComponent, [{
    type: Component,
    args: [{
      selector: 'sol-app-form-nav-picker',
      template: `
    <mat-form-field>
      <mat-label>Action</mat-label>
      <mat-select
        placeholder="Placeholder"
        [(ngModel)]="selectedOption"
        name="selectedOption"
      >
        <mat-option *ngFor="let option of options" [value]="option">
          <mat-icon>{{ option.icon }}</mat-icon>
          <span>{{ option.type }}</span>
        </mat-option>
      </mat-select>
      <mat-icon matPrefix>{{ selectedOption.icon }}</mat-icon>
    </mat-form-field>
    <div [ngSwitch]="selectedOption.type">
      <ng-container *ngSwitchCase="'Dashboard'">
        <div class="label-button-group">
          <label>{{ getSelectedLabel() }}</label>
          <button
            type="button"
            mat-stroked-button
            [solQueryPicker]="dashboards"
            [selectedItems]="[selectedDashboard]"
            displayProperty="name"
            (pick)="onDashboardPick($event)"
          >
            {{ selectedDashboard?.name || 'Select Dashboard' }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'Report'">
        <div class="label-button-group">
          <label>{{ getSelectedLabel() }}</label>
          <button
            type="button"
            mat-stroked-button
            [solQueryPicker]="reports"
            [selectedItems]="[selectedReport]"
            displayProperty="name"
            (pick)="onReportPick($event)"
          >
            {{ selectedReport?.name || 'Select Report' }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <mat-form-field>
          <mat-label>Link</mat-label>
          <input
            type="text"
            matInput
            (ngModelChange)="onLinkChange($event)"
            [ngModel]="navConfig.link"
            name="linkInput"
          />
        </mat-form-field>
      </ng-container>
    </div>
  `,
      styles: [":host{display:flex;flex-direction:column}mat-form-field{width:100%}.label-button-group{display:flex;flex-direction:column;align-items:flex-start}.label-button-group>label{font-size:.8rem}\n"]
    }]
  }], () => [{
    type: i2.HttpClient
  }, {
    type: AppFormModuleConfig
  }, {
    type: i3$1.PageService
  }], {
    navConfig: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
class AppFormComponent {
  set app(value) {
    this.form.patchValue({
      id: value?.id || '',
      name: value?.name || '',
      description: value?.description || '',
      moduleIds: value?.moduleIds || [],
      roleIds: value?.roleIds || [],
      navConfig: value?.navConfig || {
        type: '',
        dashboardId: '',
        reportId: '',
        link: ''
      }
    }, {
      emitEvent: false
    });
    this.initializing = false;
  }
  constructor(_cdr, dialogRef, _data) {
    this._cdr = _cdr;
    this.dialogRef = dialogRef;
    this._data = _data;
    this.title = '';
    this.showButtons = true;
    this.cancel = new EventEmitter();
    this.save = new EventEmitter();
    this.change = new EventEmitter();
    this.form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      moduleIds: new FormControl([]),
      roleIds: new FormControl([]),
      navConfig: new FormGroup({
        type: new FormControl(''),
        dashboardId: new FormControl(''),
        reportId: new FormControl(''),
        link: new FormControl('')
      })
    });
    this.nameChange = new EventEmitter();
    this._subs = new Subscription();
    this.initializing = true;
    if (this.dialogRef && _data) {
      const data = _data || {};
      this.title = data.title;
      this._subs.add(this.save.subscribe(result => this.dialogRef.close(result)));
    }
    // this.form.get('name')!.valueChanges.subscribe(value => {
    //   this.nameChange.emit(value ?? '');
    //   this.emitChangeEvent();
    // });
    this.form.valueChanges.subscribe(value => {
      this.emitChangeEvent(value);
    });
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }
  onModulesChange(picked) {
    this.form.get('moduleIds')?.setValue(picked.map(m => m.id));
    this.change.emit();
  }
  onRolesChange(picked) {
    this.form.get('roleIds')?.setValue(picked.map(m => m.id));
    this.change.emit();
  }
  onNavChange(navConfig) {
    const navConfigGroup = this.form.get('navConfig');
    navConfigGroup?.setValue(navConfig);
    this.change.emit();
  }
  triggerSubmit() {
    const submitEvent = new SubmitEvent('submit', {
      submitter: document.createElement('button')
    });
    this.onSubmit(submitEvent);
  }
  onSubmit(event) {
    // console.log(this.form.value);
    if (this.form.invalid) return;
    this.save.emit(this.form.value);
    this.dialogRef.close(this.form.value);
    this._cdr.detectChanges();
  }
  onCancel() {
    if (this.dialogRef) this.dialogRef.close();
    this.cancel.emit();
  }
  emitChangeEvent(app) {
    if (!this.initializing) {
      this.change.emit(app);
    }
  }
  static {
    this.ɵfac = function AppFormComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppFormComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1$1.MatDialogRef, 8), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppFormComponent,
      selectors: [["sol-app-form"]],
      inputs: {
        title: "title",
        showButtons: "showButtons",
        app: "app"
      },
      outputs: {
        cancel: "cancel",
        save: "save",
        change: "change",
        nameChange: "nameChange"
      },
      decls: 14,
      vars: 7,
      consts: [[4, "ngIf"], [1, "sol-app-form", 3, "ngSubmit", "formGroup"], [1, "sol-app-form-item"], [2, "width", "100%"], ["matInput", "", "formControlName", "name"], [3, "change", "selectedRoleIds"], [2, "margin-bottom", "10px", 3, "change", "navConfig"], ["class", "sol-app-form-item cancel-submit", 4, "ngIf"], [1, "sol-app-form-item", "cancel-submit"], ["type", "button", "mat-button", "", 3, "click"], ["type", "submit", "mat-flat-button", "", "color", "primary"]],
      template: function AppFormComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, AppFormComponent_h2_0_Template, 2, 1, "h2", 0);
          i0.ɵɵelementStart(1, "form", 1);
          i0.ɵɵlistener("ngSubmit", function AppFormComponent_Template_form_ngSubmit_1_listener($event) {
            return ctx.onSubmit($event);
          });
          i0.ɵɵelementStart(2, "div", 2)(3, "mat-form-field", 3)(4, "mat-label");
          i0.ɵɵtext(5, "App Name");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(6, "input", 4);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(7, "div", 2)(8, "sol-app-form-role-picker", 5);
          i0.ɵɵlistener("change", function AppFormComponent_Template_sol_app_form_role_picker_change_8_listener($event) {
            return ctx.onRolesChange($event);
          });
          i0.ɵɵelementStart(9, "mat-label");
          i0.ɵɵtext(10, "Role(s)");
          i0.ɵɵelementEnd()()();
          i0.ɵɵelementStart(11, "div", 2)(12, "sol-app-form-nav-picker", 6);
          i0.ɵɵlistener("change", function AppFormComponent_Template_sol_app_form_nav_picker_change_12_listener($event) {
            return ctx.onNavChange($event);
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(13, AppFormComponent_div_13_Template, 5, 2, "div", 7);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_2_0;
          let tmp_3_0;
          i0.ɵɵproperty("ngIf", ctx.title);
          i0.ɵɵadvance();
          i0.ɵɵproperty("formGroup", ctx.form);
          i0.ɵɵadvance(7);
          i0.ɵɵproperty("selectedRoleIds", ((tmp_2_0 = ctx.form.get("roleIds")) == null ? null : tmp_2_0.value) || i0.ɵɵpureFunction0(5, _c1));
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("navConfig", ((tmp_3_0 = ctx.form.get("navConfig")) == null ? null : tmp_3_0.value) || i0.ɵɵpureFunction0(6, _c2));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.showButtons);
        }
      },
      dependencies: [i2$1.NgIf, i3$2.ɵNgNoValidate, i3$2.DefaultValueAccessor, i3$2.NgControlStatus, i3$2.NgControlStatusGroup, i3$2.FormGroupDirective, i3$2.FormControlName, i4.MatFormField, i4.MatLabel, i5.MatInput, i10.MatButton, AppFormRolePickerComponent, AppFormNavPickerComponent],
      styles: ["[_nghost-%COMP%]{width:100%}.sol-app-form-item[_ngcontent-%COMP%]{margin-bottom:8px}.sol-app-form-item.cancel-submit[_ngcontent-%COMP%]{align-self:flex-end}[_nghost-%COMP%]{display:flex;flex-direction:column}[_nghost-%COMP%]   .sol-app-form[_ngcontent-%COMP%]{display:flex;flex-direction:column}@media only screen and (max-width: 600px){  .sol-app-form-popover-container{margin:58px 1px 10px;min-width:100vw;min-height:90vh;transition:all 10ms ease}}@media only screen and (min-width: 600px){  .sol-app-form-popover-container{margin:58px 1px 10px;width:600px;transition:all 10ms ease}}  .sol-app-form-popover-container .mat-mdc-dialog-surface.mdc-dialog__surface{padding:10px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppFormComponent, [{
    type: Component,
    args: [{
      selector: 'sol-app-form',
      template: `
    <h2 *ngIf="title">{{ title }}</h2>
    <form class="sol-app-form" (ngSubmit)="onSubmit($event)" [formGroup]="form">
      <div class="sol-app-form-item">
        <mat-form-field style="width: 100%">
          <mat-label>App Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
      <div class="sol-app-form-item">
        <sol-app-form-role-picker
          [selectedRoleIds]="form.get('roleIds')?.value || []"
          (change)="onRolesChange($event)"
        >
          <mat-label>Role(s)</mat-label>
        </sol-app-form-role-picker>
      </div>
      <!-- <div class="sol-app-form-item">
        <sol-app-form-module-picker
          [selectedModuleIds]="form.get('moduleIds')?.value || []"
          (change)="onModulesChange($event)"
        >
          <mat-label>Object(s)</mat-label>
        </sol-app-form-module-picker>
      </div> -->
      <div class="sol-app-form-item">
        <sol-app-form-nav-picker
          style="margin-bottom: 10px;"
          [navConfig]="this.form.get('navConfig')?.value || {}"
          (change)="onNavChange($event)"
        >
        </sol-app-form-nav-picker>
      </div>
      <div class="sol-app-form-item cancel-submit" *ngIf="showButtons">
        <button type="button" (click)="onCancel()" mat-button>Cancel</button>
        <button
          type="submit"
          [style.marginLeft.px]="8"
          mat-flat-button
          color="primary"
        >
          Save
        </button>
      </div>
    </form>
  `,
      styles: [":host{width:100%}.sol-app-form-item{margin-bottom:8px}.sol-app-form-item.cancel-submit{align-self:flex-end}:host{display:flex;flex-direction:column}:host .sol-app-form{display:flex;flex-direction:column}@media only screen and (max-width: 600px){::ng-deep .sol-app-form-popover-container{margin:58px 1px 10px;min-width:100vw;min-height:90vh;transition:all 10ms ease}}@media only screen and (min-width: 600px){::ng-deep .sol-app-form-popover-container{margin:58px 1px 10px;width:600px;transition:all 10ms ease}}::ng-deep .sol-app-form-popover-container .mat-mdc-dialog-surface.mdc-dialog__surface{padding:10px}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i1$1.MatDialogRef,
    decorators: [{
      type: Optional
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [MAT_DIALOG_DATA]
    }]
  }], {
    title: [{
      type: Input
    }],
    showButtons: [{
      type: Input
    }],
    app: [{
      type: Input
    }],
    cancel: [{
      type: Output
    }],
    save: [{
      type: Output
    }],
    change: [{
      type: Output
    }],
    nameChange: [{
      type: Output
    }]
  });
})();
const modules$1 = [MatFormFieldModule, MatInputModule, MatListModule, MatIconModule, MatButtonModule, MatChipsModule, MatCheckboxModule, MatSelectModule];
class AppFormMaterialModule {
  static {
    this.ɵfac = function AppFormMaterialModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppFormMaterialModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppFormMaterialModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [modules$1, MatFormFieldModule, MatInputModule, MatListModule, MatIconModule, MatButtonModule, MatChipsModule, MatCheckboxModule, MatSelectModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppFormMaterialModule, [{
    type: NgModule,
    args: [{
      imports: modules$1,
      exports: modules$1
    }]
  }], null, null);
})();
class AppFormModulePickerComponent {
  set selectedModuleIds(value) {
    this._selectedModuleIds = value || [];
    this.selected = this.modules.pipe(map(all => {
      const ids = new Set(this._selectedModuleIds);
      return all.filter(m => ids.has(m.id));
    }));
  }
  constructor(_modules) {
    this._modules = _modules;
    this.change = new EventEmitter();
    this.selected = of([]);
    this.separatorKeysCodes = [ENTER, COMMA];
    this.modulePickerInput = new FormControl({
      value: '',
      disabled: false
    });
    this.modules = this._modules.get().pipe(map(v => v.orderBy(m => m.name)), shareReplay(1));
    this._selectedModuleIds = [];
  }
  onPick(selected) {
    this.selectedModuleIds = selected.map(m => m.id);
    this.change.emit(selected);
  }
  remove(selected, module) {
    selected = selected.filter(m => m !== module);
    this.selectedModuleIds = selected.map(m => m.id);
    this.change.emit(selected);
  }
  static {
    this.ɵfac = function AppFormModulePickerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppFormModulePickerComponent)(i0.ɵɵdirectiveInject(i1$2.ModuleService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppFormModulePickerComponent,
      selectors: [["sol-app-form-module-picker"]],
      inputs: {
        selectedModuleIds: "selectedModuleIds"
      },
      outputs: {
        change: "change"
      },
      decls: 10,
      vars: 15,
      consts: [[1, "app-form-module-picker-form-field", 3, "pick", "floatLabel", "solQueryPicker", "displayProperty", "selectedItems", "multiple"], ["aria-label", "Module selection"], [3, "removable", "removed", 4, "ngFor", "ngForOf"], ["matInput", "", "type", "text", "autocomplete", "nope", "name", "modulePickerHiddenInput"], ["align", "end"], [3, "removed", "removable"], ["matChipAvatar", ""], ["matChipRemove", "", "aria-label", "'remove ' + keyword"]],
      template: function AppFormModulePickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "mat-form-field", 0);
          i0.ɵɵlistener("pick", function AppFormModulePickerComponent_Template_mat_form_field_pick_0_listener($event) {
            return ctx.onPick($event);
          });
          i0.ɵɵelementStart(1, "mat-label");
          i0.ɵɵtext(2, "Object(s)");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "mat-chip-listbox", 1);
          i0.ɵɵtemplate(4, AppFormModulePickerComponent_mat_chip_4_Template, 7, 3, "mat-chip", 2);
          i0.ɵɵpipe(5, "async");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(6, "input", 3);
          i0.ɵɵelementStart(7, "mat-hint", 4);
          i0.ɵɵtext(8);
          i0.ɵɵpipe(9, "async");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_8_0;
          i0.ɵɵproperty("floatLabel", "always")("solQueryPicker", ctx.modules)("displayProperty", "name")("selectedItems", ctx.selected)("multiple", true);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(5, 11, ctx.selected));
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("opacity", 0)("cursor", "pointer");
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" Selected: ", (tmp_8_0 = i0.ɵɵpipeBind1(9, 13, ctx.selected)) == null ? null : tmp_8_0.length, "");
        }
      },
      dependencies: [i2$1.NgForOf, i3.QueryPickerDirective, i4.MatFormField, i4.MatLabel, i4.MatHint, i5.MatInput, i6.MatIcon, i7.MatChip, i7.MatChipAvatar, i7.MatChipListbox, i7.MatChipRemove, i2$1.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:flex;flex-direction:column}.app-form-module-picker-form-field[_ngcontent-%COMP%]{cursor:pointer}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppFormModulePickerComponent, [{
    type: Component,
    args: [{
      selector: 'sol-app-form-module-picker',
      template: `
    <mat-form-field
      class="app-form-module-picker-form-field"
      [floatLabel]="'always'"
      [solQueryPicker]="modules"
      [displayProperty]="'name'"
      [selectedItems]="selected"
      [multiple]="true"
      (pick)="onPick($event)"
    >
      <mat-label>Object(s)</mat-label>

      <mat-chip-listbox aria-label="Module selection">
        <mat-chip
          *ngFor="let module of selected | async as selected"
          [removable]="true"
          (removed)="remove(selected, module)"
        >
          <mat-icon matChipAvatar>{{ module.icon }}</mat-icon>
          {{ module.name }}
          <button matChipRemove aria-label="'remove ' + keyword">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-listbox>

      <input matInput type="text" [style.opacity]="0" [style.cursor]="'pointer'" autocomplete="nope" name="modulePickerHiddenInput"  />

      <mat-hint align="end">
        Selected: {{ (selected | async)?.length }}</mat-hint>
    </mat-form-field>
  `,
      styles: [":host{display:flex;flex-direction:column}.app-form-module-picker-form-field{cursor:pointer}\n"]
    }]
  }], () => [{
    type: i1$2.ModuleService
  }], {
    selectedModuleIds: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
class AppFormModule {
  static forRoot(config) {
    config = {
      ...config
    };
    return {
      ngModule: AppFormModule,
      providers: [{
        provide: AppFormModuleConfig,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function AppFormModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppFormModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppFormModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AppFormModuleConfigProvider, provideHttpClient(withInterceptorsFromDi())],
      imports: [CommonModule, ReactiveFormsModule, FormsModule, ModuleModule, RoleModule, ProfileModule, QueryPickerModule, PageModule, AppFormMaterialModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppFormModule, [{
    type: NgModule,
    args: [{
      declarations: [AppFormComponent, AppFormModulePickerComponent, AppFormRolePickerComponent, AppFormNavPickerComponent],
      exports: [AppFormComponent, AppFormModulePickerComponent, AppFormRolePickerComponent, AppFormNavPickerComponent],
      imports: [CommonModule, ReactiveFormsModule, FormsModule, ModuleModule, RoleModule, ProfileModule, QueryPickerModule, PageModule, AppFormMaterialModule],
      providers: [AppFormModuleConfigProvider, provideHttpClient(withInterceptorsFromDi())]
    }]
  }], null, null);
})();
const modules = [MatButtonModule, MatListModule, MatIconModule, MatFormFieldModule, MatInputModule, MatTooltipModule, MatCheckboxModule, MatExpansionModule];
class AppListMaterialModule {
  static {
    this.ɵfac = function AppListMaterialModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppListMaterialModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppListMaterialModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [modules, MatButtonModule, MatListModule, MatIconModule, MatFormFieldModule, MatInputModule, MatTooltipModule, MatCheckboxModule, MatExpansionModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppListMaterialModule, [{
    type: NgModule,
    args: [{
      imports: modules,
      exports: modules
    }]
  }], null, null);
})();
const APP_LIST_TOOLTIPS = {
  disabledUrl: 'Please prefix with http or https respectively (non secure urls could cause unexpected behaviour) -- If this is left blank the application will handle with a generic message.'
};
class AppListItemComponent {
  constructor() {
    this.save = new EventEmitter();
    this.delete = new EventEmitter();
    this.change = new EventEmitter();
    this.updateApp = new EventEmitter();
    this._app = {};
    this._model = {};
  }
  triggerAppSubmit() {
    if (this.appForm) {
      this.appForm.triggerSubmit();
    }
  }
  set app(value) {
    if (!value) return;
    this._model = this._copy(value);
    this._app = value || {};
  }
  get app() {
    return this._app;
  }
  get expanded() {
    return this.panel.expanded;
  }
  onSave(app) {
    this.app = app;
    this.save.emit(app);
    this.panel.close();
  }
  onDelete(event) {
    event.stopPropagation();
    this.delete.emit(this.app);
  }
  onCancel() {
    this._app = this._copy(this._model);
    this.panel.close();
  }
  _copy(data) {
    return JSON.parse(JSON.stringify({
      ...data
    }));
  }
  onNameChange(newName) {
    if (this.app) {
      this.app.name = newName;
      this.handleOnNameChange();
    }
  }
  handleAppFormChange(app) {
    this.updateApp.emit(app);
  }
  handleOnNameChange() {
    this.change.emit();
  }
  static {
    this.ɵfac = function AppListItemComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppListItemComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppListItemComponent,
      selectors: [["sol-app-list-item"]],
      viewQuery: function AppListItemComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(AppFormComponent, 7);
          i0.ɵɵviewQuery(MatExpansionPanel, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appForm = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.panel = _t.first);
        }
      },
      inputs: {
        app: "app",
        expanded: "expanded"
      },
      outputs: {
        save: "save",
        delete: "delete",
        change: "change",
        updateApp: "updateApp"
      },
      ngContentSelectors: _c3,
      decls: 15,
      vars: 17,
      consts: [[1, "sol-app-list-item-panel"], ["mat-icon-button", "", "type", "button", 3, "click"], [1, "material-icons-outlined"], [3, "cancel", "save", "nameChange", "change", "showButtons", "app"]],
      template: function AppListItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "mat-expansion-panel", 0);
          i0.ɵɵelement(1, "div");
          i0.ɵɵelementStart(2, "mat-expansion-panel-header")(3, "mat-panel-title");
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "mat-panel-description")(6, "span");
          i0.ɵɵtext(7);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(8, "span");
          i0.ɵɵtext(9);
          i0.ɵɵelementEnd()();
          i0.ɵɵprojection(10);
          i0.ɵɵelementStart(11, "button", 1);
          i0.ɵɵlistener("click", function AppListItemComponent_Template_button_click_11_listener($event) {
            return ctx.onDelete($event);
          });
          i0.ɵɵelementStart(12, "mat-icon", 2);
          i0.ɵɵtext(13, "delete");
          i0.ɵɵelementEnd()()();
          i0.ɵɵelementStart(14, "sol-app-form", 3);
          i0.ɵɵlistener("cancel", function AppListItemComponent_Template_sol_app_form_cancel_14_listener() {
            return ctx.onCancel();
          })("save", function AppListItemComponent_Template_sol_app_form_save_14_listener($event) {
            return ctx.onSave($event);
          })("nameChange", function AppListItemComponent_Template_sol_app_form_nameChange_14_listener($event) {
            return ctx.onNameChange($event);
          })("change", function AppListItemComponent_Template_sol_app_form_change_14_listener($event) {
            return ctx.handleAppFormChange($event);
          });
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("border-radius", 8, "px");
          i0.ɵɵclassProp("mat-elevation-z0", true);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("min-height", 12, "px");
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("min-height", 50, "px");
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("flex-grow", 2);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.app.name);
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1("", (ctx.app.roleIds == null ? null : ctx.app.roleIds.length) || 0, " Roles(s)");
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", (ctx.app.moduleIds == null ? null : ctx.app.moduleIds.length) || 0, " Object(s)");
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("margin-right", 8, "px");
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("showButtons", false)("app", ctx.app);
        }
      },
      dependencies: [i10.MatIconButton, i6.MatIcon, i3$3.MatExpansionPanel, i3$3.MatExpansionPanelHeader, i3$3.MatExpansionPanelTitle, i3$3.MatExpansionPanelDescription, AppFormComponent],
      styles: ["@use \"@angular/material\" as mat;[_nghost-%COMP%]{justify-content:space-between;align-items:center}[_nghost-%COMP%]     sol-app-form{@include mat.form-field-density(-2);@include mat.input-density(-2);}.mat-expansion-panel-header-description[_ngcontent-%COMP%]{justify-content:space-between;align-items:center}.sol-app-list-item-headers-align[_ngcontent-%COMP%]   .mat-mdc-form-field[_ngcontent-%COMP%] + .mat-mdc-form-field[_ngcontent-%COMP%]{margin-left:8px}.sol-app-list-item-panel[_ngcontent-%COMP%]{border:1px solid rgba(0,0,0,.2);max-width:100%;width:auto}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppListItemComponent, [{
    type: Component,
    args: [{
      selector: 'sol-app-list-item',
      template: `

  <mat-expansion-panel  [class.mat-elevation-z0]="true" [style.borderRadius.px]="8"
    class="sol-app-list-item-panel">
    <div [style.minHeight.px]="12"></div>
    <mat-expansion-panel-header [style.min-height.px]="50" >
      <mat-panel-title [style.flexGrow]="2">{{ app.name }}</mat-panel-title>
      <mat-panel-description>
        <span>{{ app.roleIds?.length || 0 }} Roles(s)</span>
        <span> {{ app.moduleIds?.length || 0 }} Object(s)</span>
      </mat-panel-description>
      <ng-content></ng-content>
      <button mat-icon-button (click)="onDelete($event)" type="button" [style.marginRight.px]="8">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
    </mat-expansion-panel-header>
    <sol-app-form [showButtons]="false" (cancel)="onCancel()" (save)="onSave($event)" (nameChange)="onNameChange($event)" (change)="handleAppFormChange($event)" [app]="app"></sol-app-form>
  </mat-expansion-panel>
   `,
      styles: ["@use \"@angular/material\" as mat;:host{justify-content:space-between;align-items:center}:host ::ng-deep sol-app-form{@include mat.form-field-density(-2);@include mat.input-density(-2);}.mat-expansion-panel-header-description{justify-content:space-between;align-items:center}.sol-app-list-item-headers-align .mat-mdc-form-field+.mat-mdc-form-field{margin-left:8px}.sol-app-list-item-panel{border:1px solid rgba(0,0,0,.2);max-width:100%;width:auto}\n"]
    }]
  }], null, {
    appForm: [{
      type: ViewChild,
      args: [AppFormComponent, {
        static: true
      }]
    }],
    panel: [{
      type: ViewChild,
      args: [MatExpansionPanel, {
        static: true
      }]
    }],
    app: [{
      type: Input
    }],
    expanded: [{
      type: Input
    }],
    save: [{
      type: Output
    }],
    delete: [{
      type: Output
    }],
    change: [{
      type: Output
    }],
    updateApp: [{
      type: Output
    }]
  });
})();
class AppListComponent {
  triggerAppsSubmit() {
    this.appListItems.forEach(appListItem => appListItem.triggerAppSubmit());
  }
  constructor(_dialog) {
    this._dialog = _dialog;
    this.tooltips = APP_LIST_TOOLTIPS;
    this.apps = [];
    this.orderChange = new EventEmitter();
    this.add = new EventEmitter();
    this.remove = new EventEmitter();
    this.select = new EventEmitter();
    this.save = new EventEmitter();
    this.change = new EventEmitter();
    this.updateApp = new EventEmitter();
    this._subs = new Subscription();
  }
  onDrop(e) {
    moveItemInArray(this.apps, e.previousIndex, e.currentIndex);
    this.orderChange.emit();
    this.handleOnDrop();
  }
  onDelete(app) {
    if (window.confirm(`Are you sure you want to delete the app named "${app.name}"?`)) {
      this.remove.emit(app);
      this.handleOnDelete();
    }
  }
  handleUpdateApp(app) {
    this.updateApp.emit(app);
  }
  onAddClick(event) {
    if (this._activeRef) this._activeRef?.close();
    this._activeRef = this._openNewAppPopover();
    const suite$ = (this._activeRef?.afterClosed() || EMPTY).pipe(filter(s => !!s));
    this._subs.add(suite$.subscribe({
      next: suite => {
        this.add.emit(suite);
        this.handleOnAddClick();
        this._activeRef?.close();
      }
    }));
  }
  _openNewAppPopover() {
    return this._dialog.open(AppFormComponent, {
      data: {
        title: 'Create App'
      },
      panelClass: ['sol-app-form-popover-container']
    });
  }
  saveAllApps() {
    this.apps.forEach(app => {
      this.save.emit(app);
    });
  }
  handleAppListItem() {
    this.change.emit();
  }
  handleOnAddClick() {
    this.change.emit();
  }
  handleOnDelete() {
    this.change.emit();
  }
  handleOnDrop() {
    this.change.emit();
  }
  static {
    this.ɵfac = function AppListComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppListComponent)(i0.ɵɵdirectiveInject(i1$1.MatDialog));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppListComponent,
      selectors: [["sol-app-list"]],
      viewQuery: function AppListComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(AppListItemComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appListItems = _t);
        }
      },
      inputs: {
        suiteId: "suiteId",
        apps: "apps"
      },
      outputs: {
        orderChange: "orderChange",
        add: "add",
        remove: "remove",
        select: "select",
        save: "save",
        change: "change",
        updateApp: "updateApp"
      },
      decls: 7,
      vars: 2,
      consts: [["listRef", ""], ["item", ""], ["cdkDropList", "", 3, "cdkDropListDropped"], [4, "ngIf", "ngIfElse"], [1, "flex-col-center"], ["color", "primary", "mat-stroked-button", "", 3, "click"], ["mat-list-item", "", "class", "sol-app-list-item-container", "cdkDrag", "", 3, "cdkDragDisabled", "click", 4, "ngFor", "ngForOf"], ["mat-list-item", "", "cdkDrag", "", 1, "sol-app-list-item-container", 3, "click", "cdkDragDisabled"], [2, "width", "100%", 3, "save", "delete", "change", "updateApp", "app"]],
      template: function AppListComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "mat-list", 2);
          i0.ɵɵlistener("cdkDropListDropped", function AppListComponent_Template_mat_list_cdkDropListDropped_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onDrop($event));
          });
          i0.ɵɵtemplate(1, AppListComponent_ng_container_1_Template, 2, 0, "ng-container", 3)(2, AppListComponent_ng_template_2_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 4)(5, "button", 5);
          i0.ɵɵlistener("click", function AppListComponent_Template_button_click_5_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onAddClick($event));
          });
          i0.ɵɵtext(6, " Add Sub-Nav ");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          const listRef_r6 = i0.ɵɵreference(3);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.apps.length <= 0)("ngIfElse", listRef_r6);
        }
      },
      dependencies: [i2$1.NgForOf, i2$1.NgIf, i10.MatButton, i4$1.MatList, i5$1.CdkDropList, i5$1.CdkDrag, AppListItemComponent],
      styles: ["mat-list-option[_ngcontent-%COMP%]{border:1px solid rgba(0,0,0,.07);border-radius:8px;margin:8px 0;align-items:center;height:64px!important;box-sizing:border-box!important}app-list-item[_ngcontent-%COMP%]{display:flex;height:100%}app-list-item[_ngcontent-%COMP%]     mat-form-field{display:flex;align-items:center;justify-content:center;flex-direction:row}app-list-item[_ngcontent-%COMP%]     .mat-mdc-form-field-subscript-wrapper{display:none}.app-list-item-text[_ngcontent-%COMP%]{display:flex;flex-direction:column;margin-top:auto;margin-bottom:auto}.app-list-item-actions[_ngcontent-%COMP%]{margin-top:auto;margin-bottom:auto}div[mat-list-icon][_ngcontent-%COMP%]{padding:0 8px!important;margin-top:auto;margin-bottom:auto}  .mat-list-base .mat-list-item .mat-list-item-content-reverse,   .mat-list-base .mat-list-option .mat-list-item-content-reverse,   .mat-list-text{padding:0!important}.cdk-drag-preview[_ngcontent-%COMP%]{background-color:#fff;display:flex;box-sizing:border-box;border-radius:8px;border:1px solid rgba(0,0,0,.07);box-shadow:0 5px 5px -3px #0003,0 8px 10px 1px #00000024,0 3px 14px 2px #0000001f;font-size:12px}.cdk-drag-preview[_ngcontent-%COMP%]   .app-list-item-actions[_ngcontent-%COMP%], .cdk-drag-placeholder[_ngcontent-%COMP%]{opacity:0}.cdk-drag-animating[_ngcontent-%COMP%]{transition:transform .25s cubic-bezier(0,0,.2,1)}mat-selection-list.cdk-drop-list-dragging[_ngcontent-%COMP%]   mat-list-option[_ngcontent-%COMP%]:not(.cdk-drag-placeholder){transition:transform .25s cubic-bezier(0,0,.2,1)}.app-list-item-checkbox[_ngcontent-%COMP%]{margin:auto 16px}  mat-form-field.disabled-url-field .mat-form-field-wrapper{padding-bottom:0}  mat-form-field.disabled-url-field .mat-form-field-infix{border-top:.6em solid transparent}.flex-col-center[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:center}.sol-app-list-item-container[_ngcontent-%COMP%]{margin-bottom:10px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppListComponent, [{
    type: Component,
    args: [{
      selector: 'sol-app-list',
      template: "<mat-list cdkDropList (cdkDropListDropped)=\"onDrop($event)\">\n  <ng-container *ngIf=\"apps.length <= 0; else listRef\">\n    No apps here ...\n  </ng-container>\n  <ng-template #listRef>\n    <div\n      mat-list-item\n      class=\"sol-app-list-item-container\"\n      *ngFor=\"let app of apps\"\n      (click)=\"select.emit(app)\"\n      cdkDrag\n      [cdkDragDisabled]=\"item.expanded\"\n    >\n      <sol-app-list-item\n        #item\n        [app]=\"app\"\n        (save)=\"save.emit($event)\"\n        (delete)=\"onDelete($event)\"\n        (change)=\"handleAppListItem()\"\n        (updateApp)=\"handleUpdateApp($event)\"\n        style=\"width: 100%\"\n      >\n      </sol-app-list-item>\n    </div>\n  </ng-template>\n</mat-list>\n<div class=\"flex-col-center\">\n  <button (click)=\"onAddClick($event)\" color=\"primary\" mat-stroked-button>\n    Add Sub-Nav\n  </button>\n</div>\n",
      styles: ["mat-list-option{border:1px solid rgba(0,0,0,.07);border-radius:8px;margin:8px 0;align-items:center;height:64px!important;box-sizing:border-box!important}app-list-item{display:flex;height:100%}app-list-item ::ng-deep mat-form-field{display:flex;align-items:center;justify-content:center;flex-direction:row}app-list-item ::ng-deep .mat-mdc-form-field-subscript-wrapper{display:none}.app-list-item-text{display:flex;flex-direction:column;margin-top:auto;margin-bottom:auto}.app-list-item-actions{margin-top:auto;margin-bottom:auto}div[mat-list-icon]{padding:0 8px!important;margin-top:auto;margin-bottom:auto}::ng-deep .mat-list-base .mat-list-item .mat-list-item-content-reverse,::ng-deep .mat-list-base .mat-list-option .mat-list-item-content-reverse,::ng-deep .mat-list-text{padding:0!important}.cdk-drag-preview{background-color:#fff;display:flex;box-sizing:border-box;border-radius:8px;border:1px solid rgba(0,0,0,.07);box-shadow:0 5px 5px -3px #0003,0 8px 10px 1px #00000024,0 3px 14px 2px #0000001f;font-size:12px}.cdk-drag-preview .app-list-item-actions,.cdk-drag-placeholder{opacity:0}.cdk-drag-animating{transition:transform .25s cubic-bezier(0,0,.2,1)}mat-selection-list.cdk-drop-list-dragging mat-list-option:not(.cdk-drag-placeholder){transition:transform .25s cubic-bezier(0,0,.2,1)}.app-list-item-checkbox{margin:auto 16px}::ng-deep mat-form-field.disabled-url-field .mat-form-field-wrapper{padding-bottom:0}::ng-deep mat-form-field.disabled-url-field .mat-form-field-infix{border-top:.6em solid transparent}.flex-col-center{display:flex;flex-direction:column;align-items:center}.sol-app-list-item-container{margin-bottom:10px}\n"]
    }]
  }], () => [{
    type: i1$1.MatDialog
  }], {
    appListItems: [{
      type: ViewChildren,
      args: [AppListItemComponent]
    }],
    suiteId: [{
      type: Input
    }],
    apps: [{
      type: Input
    }],
    orderChange: [{
      type: Output
    }],
    add: [{
      type: Output
    }],
    remove: [{
      type: Output
    }],
    select: [{
      type: Output
    }],
    save: [{
      type: Output
    }],
    change: [{
      type: Output
    }],
    updateApp: [{
      type: Output
    }]
  });
})();
const AppListModuleConfig = new InjectionToken(`AppListModuleConfig`);
const AppListModuleConfigProvider = {
  provide: AppListModuleConfig,
  useValue: {
    reportApiBaseUrl: `https://report.soleran.dev`
  }
};
class AppListModule {
  static forRoot(config) {
    config = {
      ...config
    };
    return {
      ngModule: AppListModule,
      providers: [{
        provide: AppListModuleConfig,
        useValue: config
      }, {
        provide: AppFormModuleConfig,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function AppListModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppListModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppListModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ReactiveFormsModule, FormsModule, AppListMaterialModule, AppFormModule, PopoverModule, MatDialogModule, DragDropModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppListModule, [{
    type: NgModule,
    args: [{
      declarations: [AppListComponent, AppListItemComponent],
      exports: [AppListComponent, AppListItemComponent],
      imports: [CommonModule, ReactiveFormsModule, FormsModule, AppListMaterialModule, AppFormModule, PopoverModule, MatDialogModule, DragDropModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of @soleran/ngx-app
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AppFormComponent, AppFormModule, AppFormModuleConfig, AppFormModuleConfigProvider, AppFormModulePickerComponent, AppFormNavPickerComponent, AppFormRolePickerComponent, AppListComponent, AppListItemComponent, AppListModule, AppModule, AppService };
